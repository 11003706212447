<template>
  <div class="container">
    <router-link
      :to="{
        name: 'AllQuestions'
      }"
    >
      <div class="add-question-btn">
        <span style="margin-right: 5px">
          Add questions from question bank
        </span>
        <i class="fas fa-plus" />
      </div>
    </router-link>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'SatComposePractice' }">
            Questions
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ title }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h3>{{ title }}</h3>
    <hr />
    <div class="btn-list">
      <div class="btn-item-box" v-for="subject in subjects" :key="subject.id">
        <div class="btn-item-type">
          <a @click="selectType(subject.id)">
            <label :class="subjectId == subject.id ? 'btn active' : 'btn'">
              {{ titleCase(subject.sat_subject.name) }}
            </label>
          </a>
        </div>
      </div>
    </div>
    <hr />
    <div v-if="questions.length > 0">
      <draggable :list="questions" class="question-card">
        <div v-for="(question, index) in questions" :key="question.id">
          <QuestionCard :question="question" :order="index + 1"></QuestionCard>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import draggable from "vuedraggable";
import SAT from "@/apis/sat.js";
import Breadcrumb from "@/components/Breadcrumb";
import QuestionCard from "@/views/viewSatTest/components/QuestionCard.vue";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: `${this.title} - ${this.CompanyName}`
    };
  },

  components: {
    QuestionCard,
    Breadcrumb,
    draggable
  },

  mixins: [Common],

  props: [],
  data() {
    return {
      title: "",
      subjects: [],
      questions: []
    };
  },
  computed: {
    satId() {
      return this.$route.params.id;
    },
    subjectId() {
      return this.$route.query.subject_id;
    }
  },
  watch: {},

  async mounted() {
    const res = await SAT.getExamSubjects(this.satId);
    this.subjects = res.sat.sat_sat_subjects;
    this.title = res.sat.title;
    if (this.subjectId) {
      const questions = await SAT.getSatSubjectQuestions(this.subjectId, {
        page: this.page
      });
      this.questions = questions.sat_questions;
    }
  },

  methods: {
    selectType(subject_id) {
      let name = "ViewSATTest";
      this.$router.replace({
        name: name,
        params: {
          ...this.$route.params
        },
        query: {
          subject_id: subject_id
        }
      });
    }
  }
};
</script>

<style scoped>
.container {
  padding-top: 0;
}
.question-card {
  cursor: move;
}
.ivy-type-title {
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  margin: 10px 0;
}
.btn-list {
  display: flex;
  justify-content: space-between;
}
.btn-item-box {
  width: 50%;
  padding: 0 10px;
}
.btn-item {
  width: 200px;
}
.btn-item-type {
  width: 100%;
}
.btn {
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
}
.btn:hover,
.active {
  color: white;
  background: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}
.ivy-type-title {
  display: block;
}
.add-question-btn {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .ivy-type-title {
    text-align: center !important;
    height: 25px;
    line-height: 25px;
    margin: 0;
  }
  .d-xs-none {
    display: none;
  }
  .btn-list {
    display: block;
  }
  .btn-item-type,
  .btn-item-box,
  .btn-item {
    width: 100%;
  }
  .btn-item-box {
    padding: 0;
  }
  .ivy-type-title {
    display: none;
  }

  .toefl-sections {
    display: none;
  }
}
</style>
